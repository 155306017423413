






















































































import { Component, Vue, Prop, Emit, Model } from 'vue-property-decorator'

import { ProjectCollectionResource }  from '@/models/projects/ProjectCollectionResource'
import { DocumentCollectionResource } from '@/models/documents/DocumentCollectionResource'
import { ProposalCollectionResource } from '@/models/proposals/ProposalCollectionResource'

import hexRgb from 'hex-rgb'

@Component
export default class TableRow extends Vue {
  @Prop({ default: 'checkbox' })
  private readonly selectType!: string

  @Prop(Boolean)
  private readonly checked!: boolean

  @Prop(Boolean)
  private readonly canSelect!: boolean

  @Prop(Boolean)
  private readonly disabled!: boolean

  @Prop(Array)
  private readonly elements!: TableDataElement[]

  @Prop({ required: true })
  private readonly data!: ProjectCollectionResource | DocumentCollectionResource | ProposalCollectionResource

  @Prop({ required: true })
  private readonly namespace!: string

  @Prop(Object)
  private readonly route!: object

  @Prop(Boolean)
  private readonly active!: boolean

  @Prop(Boolean)
  private readonly isDraggable!: boolean

  @Prop({ type: Boolean, default: false })
  private readonly canDelete!: boolean

  @Prop({ type: Boolean, default: false })
  private isSelected!: boolean

  private hover: boolean = false

  private get hasSelect(): boolean {
    return this.canSelect
  }

  private highlightStyle(element?: TableDataElement): string {
    const hex = getComputedStyle(document.documentElement).getPropertyValue('--secondary')
    const rgba = hexRgb(hex)

    let style = this.active || this.hover ? `background-color: rgba(${rgba.red},${rgba.green},${rgba.blue}, 0.1);` : ''
    style += element && element?.width ? `width:${element.width}%;` : ''

    return style
  }

  private dragStart(ev: DragEvent): void {
    if (ev.dataTransfer) {
      ev.dataTransfer.setData('id', this.data.id.toString())
      // ev.dataTransfer.setData('name', this.data.proposal_name)
    }
  }

  private selectItem(): void {
    this.$emit('select', this.data)
  }

  private getStatusString(status: string): string {
    const statusString = status.replace('-', ' ')
    return statusString.charAt(0).toUpperCase() + statusString.slice(1)
  }

  private getStatusClass(status: string): string {
    switch (status) {
      case 'creation':
      case 'started':
        return 'creation'
      case 'sounding':
        return 'sounding'
      case 'proposed':
        return 'proposed'
      case 'finalized':
      case 'approved':
        return 'finalized'
      case 'active':
        return 'active'
      case 'declined':
      case 'review-needed':
        return 'review-needed'
      case 'being-updated':
        return 'being-updated'
      default:
        return ''
    }
  }

  private async bookmark(): Promise<void> {
    this.$emit('bookmark', this.data)
  }

  private async deleteRow(): Promise<void> {
    this.$emit('delete', this.data.id)
  }

  private goTo(): void {
    this.$emit('click', this.data)
    if (this.route) {
      this.$router.push(this.route)
    }
  }


  private getTranslation(key: string, optionalKey: string | number): string {
    const translationKey = `${this.namespace}.overview.table.row.${key}`
    return this.$te(translationKey) ? this.$t(translationKey) as string : `${optionalKey}`
  }

}
